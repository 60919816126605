.defaultContainer {
  padding: 24px;
}

/* Header Styles */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 0 24px;
}

.header-content {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
}

.search-bar {
  flex-grow: 1;
  margin: 0 24px;
}

.user-info {
  display: flex;
  align-items: center;
}

.avatar {
  height: 40px;
  border-radius: 50%;
}

.user-name {
  margin-left: 8px;
}
/* Header Styles - END */

/* Layout and Content Styles */

.site-layout-background {
  background: #fff;
}

.content-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

/* Topic Styles - WIKI & Recent */
.topic {
  color: #999999ca;
  text-align: left;
  font-weight: bold;
  margin-bottom: 8px;
}

.topic1 {
  color: #999999ca;
  text-align: left;
  font-weight: bold;
  margin-top: -5vh;
  margin-bottom: 16px;
}

/* Text and Button Styles */

.para1 {
  margin-top: -1vh;
  margin-bottom: 2vh;
  color: #999;
  text-align: center;
}

.back-text {
  margin-left: 8px;
}

.search-button {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 650px;
  margin-bottom: 20px;
}

.si {
  width: 100%;
  max-width: 400px;
}

.para2 {
  color: #999;
  font-size: 14px;
  text-align: center;
}

/* Article Section Styles */
.articles {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-wrap: wrap;
}

.article-box {
  cursor: pointer;
  flex: 1;
  width: 300px;
  max-width: 48%;
  margin: 8px;
  text-align: center;
  padding: 16px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(71, 71, 71, 0.484);
  border-radius: 10px;
  transition: 0.2s;
}
.article-box:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.145);
}

.article-box1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 23vh;
  margin-bottom: 4vh;
  text-align: center;
  padding: 16px;
  background: #e4f0ff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
}

.article-img {
  margin-top: 16px;
  height: 60px;
}

.article-para {
  margin-top: 16px;
  font-size: 20px;
}

/* Recent Articles Section Styles */
.recent-articles {
  background: #fff;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.379);
  border-radius: 10px;
}

.recent-articles ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.recent-articles li {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
}

/* history-button */

.history-button {
  display: block;
  margin: 16px auto;
  width: 50%;
  max-width: 200px;
  border-color: rgb(7, 71, 182);
  color: rgb(7, 71, 182);
  text-align: center;
}

/* Responsive Adjustments */

@media (max-width: 768px) {
  .articles {
    flex-direction: column;
    align-items: center;
  }

  .article-box {
    max-width: 100%;
    margin: 8px 0;
  }

  .si {
    max-width: 100%;
  }

  .history-button {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .article-box {
    margin: 8px 0;
    max-width: 98%;
  }
}
