.search {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.search_InputBtn_wrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid rgb(153, 153, 153); /* Shared border */
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: 0.3s;
  overflow: hidden;
}
.search_InputBtn_input {
  font-size: 14px;
  color: rgb(153, 153, 153);
  text-align: left;
  width: 80%;
  height: 100%;
  padding: 10px;
  border: none;
  outline: none;
}

.search_InputBtn_button {
  text-align: right;
  color: rgb(153, 153, 153);
  background-color: white;
  cursor: pointer;
  border: inherit;
  width: 20%;
  height: 100%;
  border: none;
  outline: none;
}

.noResultsFound {
  width: 100%;
  color: red;
}

/* Change border and icon color on hover */
.search_InputBtn_wrapper:hover {
  border: 2px solid rgb(64, 150, 255);
}

.search_InputBtn_wrapper:hover .search_InputBtn_button {
  color: rgb(64, 150, 255);
}

.search_InputBtn_input:focus ~ .search_InputBtn_button,
.search_InputBtn_wrapper.focused .search_InputBtn_button {
  color: rgb(64, 150, 255);
}

/* Mobile view */
@media (max-width: 768px) {
  .search {
    width: 100%; /* Adjust to full width on mobile */
    align-items: center; /* Center align on mobile */
  }

  .search_InputBtn_wrapper {
    flex-direction: column; /* Stack input and button vertically on mobile */
    height: auto; /* Adjust height for better fit */
  }

  .search_InputBtn_input,
  .search_InputBtn_button {
    width: 100%; /* Make both input and button take full width */
    text-align: center; /* Center text alignment on mobile */
    font-size: 18px; /* Increase font size for readability */
  }

  .search_InputBtn_button {
    border-top: 1px solid rgb(153, 153, 153); /* Add top border to separate input and button */
  }
}
