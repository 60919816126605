.card {
  /* background-color: red; */
  height: 175px;
  width: 350px;
  /* margin: 10px; */
  transition: 0.2s;
  overflow: hidden;
  cursor: pointer;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow for the 3D effect */
  border-radius: 8px; /* Rounded corners */
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.card-description {
  font-size: 14px;
  color: #555;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 20px;
  max-height: 60px;
  white-space: normal;
}

@media screen and (max-width: 500px) {
  .card {
    /* background-color: red; */
    height: 150px;
    width: 250px;
  }
}
